import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "deetz" */ '../views/HomeView.vue')
  },
  {
    path: '/statement',
    name: 'statement',
    component: () => import(/* webpackChunkName: "deetz" */ '../views/ArtistStatementView.vue')
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: () => import(/* webpackChunkName: "deetz" */ '../views/ContactsView.vue')
  },
  {
    path: '/cv',
    name: 'cv',
    component: () => import(/* webpackChunkName: "deetz" */ '../views/CVView.vue')
  },
  {
    path: '/collab',
    name: 'collab',
    component: () => import(/* webpackChunkName: "deetz" */ '../views/Collab.vue')
  },
  {
    path: '/works',
    name: 'works',
    component: () => import(/* webpackChunkName: "deetz" */ '../views/BuyWorksView.vue')
  },
  {
    path: '/projects',
    name: 'projects',
    component: () => import(/* webpackChunkName: "deetz" */ '../views/ProjectsView.vue')
  },
  {
    path: '/sub',
    name: 'sub',
    component: () => import(/* webpackChunkName: "deetz" */ '../views/SubView.vue')
  },




  {
    path: '/autobus',
    name: 'autobus',
    component: () => import(/* webpackChunkName: "deetz" */ '../views/prj/PrjAutobusView.vue')
  },
  {
    path: '/pixelhunter',
    name: 'pixelhunter',
    component: () => import(/* webpackChunkName: "deetz" */ '../views/prj/PrjPixelhunterView.vue')
  },
  {
    path: '/echo2222',
    name: 'echo2222',
    component: () => import(/* webpackChunkName: "deetz" */ '../views/prj/PrjEcho2222View.vue')
  },
  {
    path: '/bukv',
    name: 'bukv',
    component: () => import(/* webpackChunkName: "deetz" */ '../views/prj/PrjBukvView.vue')
  },
  {
    path: '/glyph',
    name: 'plyph',
    component: () => import(/* webpackChunkName: "deetz" */ '../views/prj/PrjGlyphView.vue')
  },
  {
    path: '/otperchatki',
    name: 'otperchatki',
    component: () => import(/* webpackChunkName: "deetz" */ '../views/prj/PrjOtperchatkiView.vue')
  },
  {
    path: '/ulysses',
    name: 'ulysses',
    component: () => import(/* webpackChunkName: "deetz" */ '../views/prj/PrjUlyssesView.vue')
  },
  {
    path: '/light',
    name: 'light',
    component: () => import(/* webpackChunkName: "deetz" */ '../views/prj/PrjLightView.vue')
  },
  {
    path: '/zhor',
    name: 'zhor',
    component: () => import(/* webpackChunkName: "deetz" */ '../views/prj/PrjZhorView.vue')
  },
  {
    path: '/dn',
    name: 'dn',
    component: () => import(/* webpackChunkName: "deetz" */ '../views/prj/PrjDNView.vue')
  },
  {
    path: '/homeworld',
    name: 'homeworld',
    component: () => import(/* webpackChunkName: "deetz" */ '../views/prj/PrjHomeworldView.vue')
  },

  {
    path: '/scotoma',
    name: 'scotoma',
    component: () => import(/* webpackChunkName: "deetz" */ '../views/prj/PrjScotomaView.vue')
  },
  {
    path: '/noctambular',
    name: 'noctambular',
    component: () => import(/* webpackChunkName: "deetz" */ '../views/prj/PrjNoctambularView.vue')
  },
  {
    path: '/beton',
    name: 'beton',
    component: () => import(/* webpackChunkName: "deetz" */ '../views/prj/PrjBetonView.vue')
  },
  {
    path: '/elevators',
    name: 'elevators',
    component: () => import(/* webpackChunkName: "deetz" */ '../views/prj/PrjElevatorsView.vue')
  },
  {
    path: '/krsknight',
    name: 'krsknight',
    component: () => import(/* webpackChunkName: "deetz" */ '../views/prj/PrjKrskNightView.vue')
  },
  {
    path: '/novembre',
    name: 'novembre',
    component: () => import(/* webpackChunkName: "deetz" */ '../views/prj/PrjNovembreView.vue')
  },
  {
    path: '/vlterior',
    name: 'vlterior',
    component: () => import(/* webpackChunkName: "deetz" */ '../views/prj/PrjVlteriorView.vue')
  },
  {
    path: '/krskcity',
    name: 'krskcity',
    component: () => import(/* webpackChunkName: "deetz" */ '../views/prj/PrjKrskCityView.vue')
  },
  {
    path: '/krskcitizens',
    name: 'krskcitizens',
    component: () => import(/* webpackChunkName: "deetz" */ '../views/prj/PrjKrskCitizensView.vue')
  },
  {
    path: '/twbs',
    name: 'twbs',
    component: () => import(/* webpackChunkName: "deetz" */ '../views/prj/PrjTWBSView.vue')
  },
  {
    path: '/d',
    name: 'd',
    component: () => import(/* webpackChunkName: "deetz" */ '../views/prj/PrjDView.vue')
  },
  {
    path: '/ambush3',
    name: 'ambush3',
    component: () => import(/* webpackChunkName: "deetz" */ '../views/prj/PrjAmbush3View.vue')
  },
  {
    path: '/ambush2',
    name: 'ambush2',
    component: () => import(/* webpackChunkName: "deetz" */ '../views/prj/PrjAmbush2View.vue')
  },
  {
    path: '/ambush1',
    name: 'ambush1',
    component: () => import(/* webpackChunkName: "deetz" */ '../views/prj/PrjAmbush1View.vue')
  },
  {
    path: '/forty',
    name: 'forty',
    component: () => import(/* webpackChunkName: "deetz" */ '../views/prj/PrjFortyView.vue')
  },
  {
    path: '/nihilnihilnihil',
    name: 'nihilnihilnihil',
    component: () => import(/* webpackChunkName: "deetz" */ '../views/prj/PrjNihilnihilnihilView.vue')
  },
  {
    path: '/dramaticcar',
    name: 'dramaticcar',
    component: () => import(/* webpackChunkName: "deetz" */ '../views/prj/PrjDramaticCarView.vue')
  },
  {
    path: '/bibl',
    name: 'bibl',
    component: () => import(/* webpackChunkName: "deetz" */ '../views/prj/PrjBiblView.vue')
  },
  {
    path: '/erevya',
    name: 'erevya',
    component: () => import(/* webpackChunkName: "deetz" */ '../views/prj/PrjErevyaView.vue')
  },
  {
    path: '/vnezapno',
    name: 'vnezapno',
    component: () => import(/* webpackChunkName: "deetz" */ '../views/prj/PrjVnezapnoView.vue')
  },
  {
    path: '/singletones',
    name: 'singletones',
    component: () => import(/* webpackChunkName: "deetz" */ '../views/prj/SingletonesView.vue')
  },




  {
    path: '/x2023dojti',
    name: 'x2023dojti',
    component: () => import(/* webpackChunkName: "deetz" */ '../views/xhb/X2023DojtiView.vue')
  },
  {
    path: '/x2023xfiles',
    name: 'x2022xfiles',
    component: () => import(/* webpackChunkName: "deetz" */ '../views/xhb/X2023XfilesView.vue')
  },
  {
    path: '/x2022sverx',
    name: 'x2022sverx',
    component: () => import(/* webpackChunkName: "deetz" */ '../views/xhb/X2022CBEPXView.vue')
  },
  {
    path: '/x2022110',
    name: 'x2022110',
    component: () => import(/* webpackChunkName: "deetz" */ '../views/xhb/X2022110View.vue')
  },
  {
    path: '/x2022humfactor',
    name: 'x2022humfactor',
    component: () => import(/* webpackChunkName: "deetz" */ '../views/xhb/X2022HumFactorView.vue')
  },
  {
    path: '/x2022dojti',
    name: 'x2022dojti',
    component: () => import(/* webpackChunkName: "deetz" */ '../views/xhb/X2022DojtiView.vue')
  },
  {
    path: '/x2022Andrey',
    name: 'x2022Andrey',
    component: () => import(/* webpackChunkName: "deetz" */ '../views/xhb/X2022AndreyView.vue')
  },
  {
    path: '/x2022AA',
    name: 'x2022AA',
    component: () => import(/* webpackChunkName: "deetz" */ '../views/xhb/X2022AAView.vue')
  },
  {
    path: '/x2021mix',
    name: 'x2021mix',
    component: () => import(/* webpackChunkName: "deetz" */ '../views/xhb/X2021MixView.vue')
  },
  {
    path: '/x2021dojti',
    name: 'x2021dojti',
    component: () => import(/* webpackChunkName: "deetz" */ '../views/xhb/X2021DojtiView.vue')
  },
  {
    path: '/x2020ulysses',
    name: 'x2020ulysses',
    component: () => import(/* webpackChunkName: "deetz" */ '../views/xhb/X2020UlyssesView.vue')
  },
  {
    path: '/x2020dojti',
    name: 'x2020dojti',
    component: () => import(/* webpackChunkName: "deetz" */ '../views/xhb/X2020DojtiView.vue')
  },
  {
    path: '/x2017collpriv',
    name: 'x2017collpriv',
    component: () => import(/* webpackChunkName: "deetz" */ '../views/xhb/X2017CollPrivView.vue')
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView()
  }
})

export default router
