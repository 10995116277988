<template>
  <v-app>
    <v-system-bar color="white">
      <v-row class="justify-end">
        <v-col cols="2" class="d-none d-md-flex">
          <v-select :items=langs label="Language" v-model="lang" @input="changeLang()"></v-select>
        </v-col>
        <v-col cols="4" class="d-flex d-md-none mr-0 pr-0">
          <v-select :items=langs label="Language" v-model="lang" @input="changeLang()"></v-select>
        </v-col>
      </v-row>
    </v-system-bar>

    <router-view/>

    <nav class="d-none d-md-block">
      <span v-for="(item, i) in main[0].buttons" :key="i"><router-link :to="item.link"><v-btn class="ma-1 button" outlined>{{ item.name }} </v-btn></router-link><span v-if="i<6"> | </span></span>
    </nav>

    <nav class="d-block d-md-none">
      <span v-for="(item, i) in main[0].buttons" :key="i"><router-link :to="item.link"><v-btn class="ma-1 button" outlined>{{ item.name }} </v-btn></router-link></span>
    </nav>

  </v-app>
</template>

<script>
import content from "@/assets/content.json";

export default {
  name: "PrjAppView",

  data() {

    return {

      langs: ["en", "ru"],
      lang: "",

      content: ""

    }
  },

  computed: {

    main() {
      return (localStorage.getItem("lang") === "en") ? content.filter(item => item.lang === "en") : content.filter(item => item.lang === "ru")
    },
  },

  methods: {
    changeLang() {
      if (this.lang === "ru") {
        localStorage.setItem("lang", "ru")
        location.reload()
      }
      if (this.lang === "en") {
        localStorage.setItem("lang", "en")
        location.reload()
      }

    }
  },
}
</script>

<style lang="scss">

#app {
  font-family: "Courier New";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000000;
}

nav {
  padding: 20px;
  color: black;

  a {
    font-weight: bold;
    color: #000000;

    &.router-link-exact-active {
      color: #ffffff;

    }
  }
}

.alltext {
  margin-left: 2%;	/* Отступ слева */
  font-family: "Courier New";  /* шрифтец */
  text-align: left;
  line-height: 1.1;
  white-space: pre-line;
}

    /* страницы выставок */

.tit {
  font-family: "Courier New";  /* шрифтец */
  text-align: left;
  font-size: 150%;
  font-weight: 600;
}

.anno {
  font-family: "Courier New";  /* шрифтец */
  text-align: left;
  font-size: 100%;
}

.main{
  max-width: 1200px;
}


    /* тут всякие полезные штуки для страниц проектов  */

.s-carousel-container
{
  height: 100%;
  width: 100%;
  display: grid;
  justify-items: center;
  align-items: center;
  margin: 1px;
}

.sold img
{
  height: 30px;
  width: 30px;
  padding: 0px;
}

.projanno
{
  text-align: left;
  text-indent: 20px;
  line-height: 1.1;
  font-size: 15px;
  white-space: pre-line;
}

.projexe
{
  text-align: right;
  margin-top: 20px;
  font-size: 12px;
  white-space: pre;
}

@media all and (max-width: 960px) {

  .button{
    width: 75%;
  }

  .alltext {
    margin-left: 5%;	/* Отступ слева */
    margin-right: 5%;
    font-family: "Courier New";  /* шрифтец */
    font-size: 75%;
    text-align: left;
    line-height: 1.2;
    white-space: pre-line;
  }

  .projanno  {
    text-align: left;
    text-indent: 20px;
    line-height: 1.3;
    font-size: 15px;
    white-space: pre-line;
    font-size: 75%;
  }

  .projexe  {
    text-align: right;
    margin-top: 20px;
    font-size: 12px;
    white-space: pre;
    line-height: 1.2;

  }
  .photo{
    max-width: 350px;
    max-height: 350px;
  }

  /* страницы выставок */

  .tit {
    font-family: "Courier New";  /* шрифтец */
    text-align: left;
    font-size: 100%;
    font-weight: 600;
  }

  .anno {
    font-family: "Courier New";  /* шрифтец */
    text-align: left;
    font-size: 75%;
  }

}

@font-face {
  font-family: "Courier New";
  src: local("Slange Bold"), url(./assets/fonts/couriercyr.ttf) format("truetype");
}

</style>
